.Kid-Population {
  .marketplace-details-points,
  .rewards-item-panel-value,
  .lotto-modal-points-value,
  .point-value-section,
  .points-won,
  .survey-point,
  .fantasy-history-point,
  .leaderboard-points,
  .wallet-points-value,
  .point-value,
  .points-balance,
  .success-points-used,
  .poll-points {
    display: none !important;
  }
}
