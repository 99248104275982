@import './sass/variables';
@import '@fgb/portal-component-library/styles';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';

/* ELEMENT STYLING */
html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    width: 100%;
    font-family: $font-default;
    @if $light-theme {
      background: transparent;
      color: black;
    } @else {
      background: #000;
      color: white;
    }
  }
}

h1,
h2,
h3,
h4,
h5,
.title-font {
  text-transform: none;
  font-family: 'museo-sans', sans-serif;
  font-weight: 500 !important;
  font-style: normal;
}

h2,
h5 {
  font-size: 22px !important;
}

/* Placeholders */

.no-items {
  .no-results-placeholder-title {
    font-family: $font-heading;
    font-size: 25px;
    font-weight: 400;
  }

  .no-results-placeholder-description {
    font-size: 0.875rem;
    color: $black;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

.nav-secondary {
  display: flex;
  align-items: center;
  .nav-item.active {
    @media (max-width: 991px) {
      width: 5%;
    }
  }
}

$primary: $primary-color;

/* BOOTSTRAP OVERRIDES */
$theme-colors: (
  'primary': $primary-color,
  'secondary': $secondary-color,
  'tertiary': $tertiary-color,
  'quaternary': $quaternary-color,
  'primary-alternate': $primary-alternate,
  'muted': $muted-color,
  'disabled': $disabled-color,
  'info': $info-color,
  'grey': $grey-color,
  'success': $success-color,
  'danger': $error-color,
  'auction': $auction-color,
  'marketplace': $marketplace-color,
  'mp-unique-code': $tertiary-color,
  'mp-download': $secondary-color,
  'mp-event': $events-color,
  'mp-virtual-event': $events-color,
  'mp-product': $tertiary-color,
  'lotto': $lotto-color,
  'wallet': $wallet-color,
  'points': $points-color,
  'ecash': $ecash-color,
  'predictor': $predictor-color,
  'poll': $poll-color,
  'quiz': $quiz-color,
  'affiliate': $primary-color,
  'transfer': $primary-color,
  'white': #ffffff,
  'black': #000000,
  'sold-out': #70707033,
  'coming-soon': #70707033,
  'limit-reached': #dc3545,
  'not-enough-points': #dc3545,
  'add-card': $primary-color,
  'active-card': $primary-color,
  'inactive-card': $disabled-color,
  'frozen-card': $frozen-color,
  'item-locked': #70707033,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

$sizes: (
  90: 90%,
);

$grid-gutter-width: 22px;

$enable-rounded: true;
$enable-validation-icons: false;

$border-radius-base: 4px;

$box-shadow-sm: 0 0 0.25rem rgba(#000, 0.15);
$box-shadow: 0 0.1rem 0.5rem rgba(#000, 0.15);

$date-select-box-shadow: 0 0 0 0.2rem rgb(60 85 124 / 25%);

.font-heading {
  font-family: $font-heading;
}

.bg-gold {
  background-color: #b9a768;
}

/* Form Colors */

.form-control {
  color: #000000;
}

/* Banner */

.banner {
  background: url('../src/assets/images/banner.jpg');
  background-size: cover !important;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  @media (max-width: 991px) {
    background: url('../src/assets/images/mobile-banner.jpg');
  }
  h1 {
    color: $white;
    text-transform: capitalize;
  }
}

.login-banner {
  background-image: url(~src/assets/images/login-registration/login-banner-demo.jpg);
  background-size: cover;
  background-position: center;
}

.nav.nav-secondary .nav-item .icon,
.nav.nav-secondary .nav-item label {
  color: #707070;
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid red;
  }
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }
  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.filter-modal-dialog {
  display: flex;
  align-items: flex-end !important;
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  color: #000;
  padding: 0 10px 15px 15px;
}

/** Popup home screen shortcut */

.popup-add-to-home-screen-ios-modal-window {
  &.modal {
    height: fit-content;
    top: unset;
    bottom: 0px;
  }
}

.popup-add-to-home-screen-android-modal-window {
  &.modal {
    .modal-dialog {
      width: 290px;
      float: right;
    }
  }
}

.popup-add-to-home-screen-dialog {
  .modal-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
}

/* Placeholder Styling */

.no-items {
  .no-results-placeholder-icon {
    max-width: 200px;
    margin: 18px auto;
  }

  .no-results-placeholder-title {
    font-size: 1rem;
    font-family: $font-heading;
  }

  .no-results-placeholder-text {
    font-size: 0.75rem;
  }

  &.no-results-placeholder-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-color;
    height: 50px;
    @media (max-width: 991px) {
      margin: 0px;
    }

    .no-results-placeholder {
      font-size: 0.75rem;
    }
  }
}

.redeem-list-item-icons {
  font-size: 18px;
}

/* Errors */

.alert-danger {
  padding: 0 !important;
  margin-top: 4px;
  font-size: 0.625rem;
  background-color: white !important;
  color: $error-color !important;
  border: none !important;
}

.all-offers-steps {
  .card-link-earn-step-box {
    .card-title {
      font-size: 14px;
      color: #c80550;
    }
    .color-1 {
      color: #c80550;
    }
    .color-2 {
      color: #82bee6;
    }
    .color-3 {
      color: #7d4605;
    }
    .card-link-earn-step-icon-border-1 {
      border: 1px solid #c80550;
    }
    .card-link-earn-step-icon-border-2 {
      border: 1px solid #82bee6;
    }
    .card-link-earn-step-icon-border-3 {
      border: 1px solid #7d4605;
    }
  }
}

.offer-button {
  span {
    font-family: 'Material Icons';
  }
}

.nav.nav-secondary {
  .nav-item {
    &.active,
    &:active,
    &:hover {
      background-color: transparent;
    }
    background-color: transparent;
  }
}

.announcements-carousel {
  .owl-item {
    @media only screen and (min-width: 992px) {
      margin-left: 6px !important;
      margin-right: 2px !important;
      width: 944px !important;
    }
  }
  .owl-carousel .owl-stage-outer {
    padding-left: 0px !important;
  }
}

fgbcl-number-input {
  .quantity-select-container {
    margin: auto !important;
  }
}

@media (min-width: 576px) {
  .add-child-modal .modal-dialog {
    max-width: 625px !important;
  }
}

.add-child-modal .modal-content {
  overflow: visible;
}

.register-panel {
  .container {
    > *:not(router-outlet) {
      display: flex;
      flex-direction: column;
      height: inherit;
    }
  }
}

.registration-payment-modal {
  .registration-payment-modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
    background-color: $primary-color;
    color: #ffffff;

    .registration-payment-modal-title {
      margin-left: 10px;
      margin-bottom: 0;
      font-size: 18px;
    }

    .close-btn {
      cursor: pointer;
      margin-left: auto;
      font-family: 'Material Icons Outlined';
    }
  }

  .payment-container {
    display: flex;
    justify-content: center;
  }

  .payment-form {
    max-width: 310px;
  }
}

.invalid-token-modal {
  max-width: 700px;

  .modal-content {
    min-height: 280px;
    padding: 15px;
  }
}

/** Games Page */
.games {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }

          .survey-container {
            &.bg-poll {
              @media (max-width: 991px) {
                margin-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

/** Scorecard Carousel */
.badge-carousel-div {
  .d-lg-none {
    .owl-carousel {
      .owl-stage-outer {
        @media (max-width: 991px) {
          padding-left: 0px;
        }
        .owl-item {
          @media (max-width: 991px) {
            padding: 0px 5px;
          }
        }
      }
    }
  }
}

/** Rewards */

.marketplace-wallet-download-btn {
  background-color: $primary-color !important;
  border-color: transparent !important;
}

.rewards-details-desc-terms .accordion-button:not(.collapsed) {
  color: $primary-color;
}

.lotto-modal-price,
.lotto-modal-entries-total,
.quantity-value,
.point-value,
.confirmation-attribute-value,
.confirmation-custom-field-value {
  font-size: 0.875rem;
}

fgbcl-confirm-modal,
fgbcl-marketplace-purchase-confirmation {
  hr {
    border-top: 1px solid #dedddd;
  }
}

.font-size-14.icon.material-icons.ps-2.fw-bold {
  font-size: 14px !important;
}

/** Scorecards */

.scorecard-description-name,
.scorecard-description-linked-action .scorecard-description-congratulation-title {
  font-family: $font-default !important;
  font-size: 0.875rem !important;
  font-weight: bold !important;
}

.scorecard-description-description {
  font-size: 0.75rem !important;
}

.scorecard-prize-label,
.scorecard-prize-text {
  color: $primary-color;
}

.scorecard-description-share .share-icon {
  background-color: $primary-color;
}

.scorecard-description-modal-desc-terms .accordion-button:not(.collapsed) {
  color: $primary-color !important;
}

#scorecard-modal-name,
.scorecard-description-modal-congratulation-title {
  font-size: 1rem !important;
}

#scorecard-desc {
  margin-bottom: 15px;
}

/** Registration */

fgbcl-registration-terms-and-conditions {
  @media (max-width: 992px) {
    .wrap-accept-box {
      margin: 0 !important;
      padding-bottom: 20px !important;
    }
  }
  .registration-terms-and-condition h5 {
    margin-bottom: 0;
  }

  .terms-section-box {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/** Tertiary */

.contact-us-input-group {
  padding: 10px;
}

.contact-us-input-group:focus-visible {
  outline: none;
}

fgbcl-contact-us {
  .success-message {
    color: $success-color;
  }
}

fgb-vouchers {
  fgbcl-errors {
    p {
      position: absolute;
      width: 100%;
      bottom: -18px;
    }
  }
}

.earn-sub-heading {
  font-size: 22px !important;
}

.earn-points-container .earn-card-body .earn-card-points-value {
  font-size: 20px !important;
}

.earn-points-container .earn-card-body .earn-card-display {
  font-size: 0.75rem;
  margin-top: 0.5rem !important;
}

fgbcl-earn-points-card {
  .col-9 {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.faq-section-card-title {
  font-family: $font-default;
  font-size: 0.875rem !important;
  font-weight: bold;
}

.faq-section-card-description {
  font-size: 0.875rem !important;
}

.contact-us-dropdown {
  font-size: 0.75rem;
}

/** Announcements */

.announcements-text .announcements-title {
  font-weight: bold !important;
}

/** Notifications */

.notification-link-url,
.notification-icon {
  color: $primary-color;
}

/** Share */

.loyalty-share,
.scorecard-description-share {
  display: none;
}

@import 'bootstrap/scss/bootstrap';

/* Requiring Bootstrap */
@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;
    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }
    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}
